import * as React from "react";
import Layout from "../components/layout";
import { Col, Row } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";

const IndexPage = () => {
  return (
    <Layout>
      <Row className="mb-3">
        <Col>
          <StaticImage
            src="../images/img_procedures.jpg"
            alt=""
            placeholder="blurred"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <h1 className="mb-5">Our Procedures for Women</h1>
          <h2 className="mb-4">Body</h2>
          <h3 className="fs-5">Butt Lift / Butt Augmentation</h3>
          <p>
            With this treatment, you can enlarge, re-shape or lift your
            buttocks.
          </p>
          <h3 className="fs-5">Liposuction</h3>
          <p>
            The liposuction is one of the most popular beauty treatments. With
            this treatment, you can quickly and safely remove excess fat from
            almost all body parts.
          </p>

          <h2 className="mb-4">Breast</h2>
          <h3 className="fs-5">Breast Augmentation</h3>
          <p>
            This treatment will make your wish for a increased breast size
            reality. With either silicon implants or fat injection, you can
            reach nearly any size you want.
          </p>
          <h3 className="fs-5">Breast Lift</h3>
          <p>
            The breast lift procedure will re-shape your breasts in a minimally
            invasive surgery with no side effects. This treatment is especially
            effective if you have been breastfeeding for a long time or if you
            have lost a lot of weight, causing your breast to sag.
          </p>
          <h3 className="fs-5">Breast Reduction</h3>
          <p>
            If you are unhappy with the size of your breasts, a breast reduction
            will give you a breast size that you feel more comfortable with. In
            this procedure, we remove fat and excess skin in the breast area to
            reach the size that you desire. This treatment is usually combined
            with a breast lift.
          </p>
        </Col>
      </Row>
    </Layout>
  );
};

export default IndexPage;
